<!--
 * @Author: your name
 * @Date: 2021-02-07 12:14:28
 * @LastEditTime: 2021-02-07 13:17:47
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \sd-vue-admin\src\components\goodslist\index.vue
-->
<template>
  <div class="wrapper">
    <el-autocomplete
      v-model="goods_keyword"
      clearable
      class="inline-input"
      :popper-class="popperClass"
      :fetch-suggestions="querySearch"
      value-key="goods_name"
      placeholder="请输入商品名称、简拼"
      :disabled="isB"
      @select="handleSelect"
      @input="handleInput"
    >
      <i v-if="search" slot="suffix" class="el-input__icon el-icon-search"></i>
      <!-- @click="iconClick" -->
      <template slot-scope="{ item }">
        <div class="wrap" style="display: flex">
          <div class="name" style="width: 50px; margin-right: 120px">
            {{ item.goods_name }}
          </div>
          <span class="addr">{{ item.specs }}</span>
        </div>
      </template>
    </el-autocomplete>

    <!-- <el-button
      icon="el-icon-search"
      circle
      class="icon-btn"
      @click="iconClick"
    ></el-button> -->
    <!-- <goods-search-dialog
      ref="goodsSearchDialog"
      :brand-ids="brandId"
      @add-rows="addRows"
    ></goods-search-dialog> -->
  </div>
</template>
<script>
  import { getInputList } from '@/api/goodsSearchInput'
  // import GoodsSearchDialog from '@/baseComponents/goodsChooseDialog'
  export default {
    // name: 'GoodsSearch',
    // components: {
    //   GoodsSearchDialog,
    // },
    props: {
      fKey: {
        type: String,
        default: () => '',
      },
      // 是否判断brandid为空就警告
      isBrand: {
        type: Boolean,
        default: () => false,
      },
      search: {
        type: Boolean,
        default: () => true,
      },
      disab: {
        type: Boolean,
        default: () => false,
      },
      brandId: {
        type: String,
        default: () => '',
      },
      popperClass: {
        type: String,
        default: () => 'selectDC',
      },
    },
    data() {
      return {
        res: [],
        goods_keyword: '',
      }
    },
    computed: {
      isB() {
        if ((this.brandId == '' && this.isBrand) || this.disab) {
          return true
        } else {
          return false
        }
      },
    },
    watch: {
      goods_keyword(val) {
        this.$emit('choose-goods-keyword', val)
      },
      fKey(val) {
        if (val) {
          this.goods_keyword = val
        } else {
          this.goods_keyword = ''
        }
      },
    },
    created() {
      // console.log(this.fKey)
      this.goods_keyword = this.fKey
      // this.fetchData()
    },
    mounted() {},
    methods: {
      async fetchData(ky = '') {
        let { data, code, msg } = await getInputList({
          pageNo: 1,
          pageSize: -1,
          keyword: ky,
          brand_id: this.brandId,
        })
        console.log('123')
        console.log(data)
        if (code == 200) {
          this.res = data
          return data
        } else {
          this.$message.error(msg)
        }
      },
      querySearch(queryString, cb) {
        if (queryString == '') {
          cb([])
        } else {
          this.fetchData(queryString).then((val) => {
            console.log(val)
            cb(val)
          })
        }
      },
      addRows(val) {
        console.log(val)
        console.log('添加rows')
        this.$emit('add-rows', val)
      },
      handleSelect(val) {
        console.log(val)
        this.$emit('select-goods', val.goods_id)
        this.$emit('select-goods-all', val)
      },
      handleInput(val) {
        console.log(val)
        if (val == '') {
          this.$emit('select-goods', '')
          this.$emit('select-goods-all', { arr_unit: [] })
        } else {
          this.$emit('select-goods', '')
          this.$emit('select-goods-all', { arr_unit: [] })
        }
      },
      // iconClick() {
      //   console.log('111')
      //   this.$refs.goodsSearchDialog.showDialog = true
      // },
      resetForm() {
        this.goods_keyword = ''
      },
    },
  }
</script>
<style lang="scss" scoped>
  .icon-btn {
    position: absolute;
    top: 2px;
    right: 1px;
    border: 0px solid transparent;
  }
</style>
