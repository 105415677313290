var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "el-autocomplete",
        {
          staticClass: "inline-input",
          attrs: {
            clearable: "",
            "popper-class": _vm.popperClass,
            "fetch-suggestions": _vm.querySearch,
            "value-key": "goods_name",
            placeholder: "请输入商品名称、简拼",
            disabled: _vm.isB,
          },
          on: { select: _vm.handleSelect, input: _vm.handleInput },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (ref) {
                var item = ref.item
                return [
                  _c(
                    "div",
                    { staticClass: "wrap", staticStyle: { display: "flex" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "name",
                          staticStyle: {
                            width: "50px",
                            "margin-right": "120px",
                          },
                        },
                        [_vm._v(" " + _vm._s(item.goods_name) + " ")]
                      ),
                      _c("span", { staticClass: "addr" }, [
                        _vm._v(_vm._s(item.specs)),
                      ]),
                    ]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.goods_keyword,
            callback: function ($$v) {
              _vm.goods_keyword = $$v
            },
            expression: "goods_keyword",
          },
        },
        [
          _vm.search
            ? _c("i", {
                staticClass: "el-input__icon el-icon-search",
                attrs: { slot: "suffix" },
                slot: "suffix",
              })
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }